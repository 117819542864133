@import url(./reset.css);

/* Estos son los estilos bases para el sitio */
* {
    box-sizing: border-box;
    font-family: "Raleway", sans-serif;
    margin: 0;
    padding: 0;
}

